import styles from './index.module.scss'
import { SaleType } from '@/models/sale'
import classNames from 'classnames'
import { useMemo } from 'react'

type Props = {
  saleType: SaleType
}

export const SaleTypeBadge = ({ saleType }: Props) => {
  const badgeClasses = useMemo(() => {
    return classNames(styles.container, {
      [styles.firstServed]: saleType === SaleType.FIRST_SERVED,
      [styles.raffleDrawing]: saleType === SaleType.RAFFLE_DRAWING,
    })
  }, [saleType])

  return <div className={badgeClasses}>{saleType}</div>
}
