import { useState, useCallback } from 'react'
import * as Sentry from '@sentry/nextjs'
import { Banner } from '@/models/banner'
import { Game, GameStatus } from '@/models/game'
import { SaleCard } from '@/models/sale'
import { ListPointLottery } from '@/models/point'
import { HoopState } from '@/models/hook'
import { getFirebaseLoginToken } from '@/lib/firebase/auth'
import { getHomeAccessCount as getFirestoreHomeAccessCount, setHomeAccessCount as setFirestoreHomeAccessCount } from '@/lib/firebase/home-access-count'
import { listPublishedBanner } from '@/lib/grpc/list-published-banner'
import { listGames } from '@/lib/grpc/list-games'
import { listGameRanks } from '@/lib/grpc/list-game-ranks'
import { listAvailableLotteries } from '@/lib/grpc/list-available-lotteries'
import { BaseHoopError, HoopErrorUnknown } from '@/lib/error'
import { useSiteData } from '@/hooks/use-site-data'
import { listAvailableSaleCards } from '@/lib/grpc/app/list-available-sale-cards'

// page data state
type GetPageDataState = HoopState<
  {
    bannerList: Banner[]
    games: Game[]
    specialSales: SaleCard[]
    regularSales: SaleCard[]
    matchingGame?: Game
    resultGame?: Game
    homeAccessCount?: number
    currentPoint: number
    lotteries: ListPointLottery[]
  },
  HoopErrorUnknown
>

// 自分のランキングページ取得 state
type GetMyRankPageState = HoopState<
  {
    page: number
  },
  HoopErrorUnknown
>

export function useHome() {
  const [state, setState] = useState<GetPageDataState>()
  const [stateMyRankPage, setStateMyRankPage] = useState<GetMyRankPageState>()
  const { firebaseUid, user } = useSiteData()

  const getPageData = useCallback(async () => {
    try {
      const token = await getFirebaseLoginToken()

      if (!token) {
        throw new HoopErrorUnknown('cannot get login token')
      }

      if (!firebaseUid) {
        throw new HoopErrorUnknown('cannot get login uid')
      }

      if (!user || !user.nickname || !user.userName) {
        throw new HoopErrorUnknown('not create user')
      }

      const homeAccessCount = await getFirestoreHomeAccessCount(firebaseUid)
      const bannerList = await listPublishedBanner(token)
      const games = await listGames(token)
      const { specialSales, regularSales } = await listAvailableSaleCards(token)

      // NOTE: 現在試合中 Game
      const matchingGame = games.find((game) => game.gameStatus === GameStatus.START_MATCH)

      // NOTE: 前回のGame
      const resultGame = games
        .sort((a, b) => {
          return a.date > b.date ? -1 : 1
        })
        .find((game) => game.gameStatus === GameStatus.END_MATCH)

      // ポイントくじ
      const { currentPoint, lotteries } = await listAvailableLotteries(token)

      setState({
        isLoading: false,
        result: {
          bannerList,
          games,
          specialSales,
          regularSales,
          matchingGame,
          resultGame,
          homeAccessCount,
          currentPoint,
          lotteries,
        },
        error: undefined,
      })
    } catch (error) {
      Sentry.captureException(error)
      setState({
        isLoading: false,
        result: undefined,
        error: error instanceof BaseHoopError ? error : new HoopErrorUnknown('faild home page data'),
      })
    }
  }, [firebaseUid, user])

  // 「自分の順位」を押したときに何ページ目かを取得
  const getMyRankPageNumber = useCallback(async (gameId: string, userId: string) => {
    try {
      const token = await getFirebaseLoginToken()
      if (!token) {
        throw new HoopErrorUnknown('cannot get token')
      }

      const { currentPage } = await listGameRanks(token, gameId, 1, userId)

      setStateMyRankPage({
        isLoading: false,
        result: {
          page: currentPage,
        },
        error: undefined,
      })
    } catch (error) {
      Sentry.captureException(error)
      setStateMyRankPage({
        isLoading: false,
        result: undefined,
        error: error instanceof HoopErrorUnknown ? error : new HoopErrorUnknown('faild getMyRankPageNumber data'),
      })
    }
  }, [])

  const setHomeAccessCount = useCallback(async (count: number) => {
    try {
      if (!firebaseUid) {
        throw new HoopErrorUnknown('cannot get login uid')
      }

      await setFirestoreHomeAccessCount(firebaseUid, count)
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [])

  return {
    state,
    getPageData,
    stateMyRankPage,
    getMyRankPageNumber,
    setHomeAccessCount,
  }
}
