import styles from './index.module.scss'
import { SubHeadLine } from '@/components/commons/sub-headline'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SaleCardItem } from '@/components/home/store/regular-cards/sale-card-item'
import { SaleCard } from '@/models/sale'
import { useMemo } from 'react'
import { Mousewheel } from 'swiper'

type Props = {
  saleCards: SaleCard[]
}

export const RegularCards = ({ saleCards }: Props) => {
  const swiperSlideElements = useMemo(() => {
    return saleCards.slice(0, 10).map((item, i) => {
      return (
        <SwiperSlide key={i}>
          <SaleCardItem saleCard={item} />
        </SwiperSlide>
      )
    })
  }, [saleCards])

  return (
    <div className={styles.container}>
      <div className={styles.subHeadline}>
        <SubHeadLine titleJa={'通常販売'} titleEn={'Regular Cards'} />
      </div>
      <Swiper modules={[Mousewheel]} className={styles.list} slidesPerView={2.58088} spaceBetween={16}>
        {swiperSlideElements}
      </Swiper>
    </div>
  )
}
