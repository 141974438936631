import styles from './details.module.scss'
import { useRemainTime } from '@/hooks/use-remain-time'
import dayjs from 'dayjs'
import { useMemo, useState, useEffect } from 'react'
import { Game, GameStatus } from '@/models/game'
import classNames from 'classnames'
import { Image } from '@/components/commons/image'

type Props = {
  game: Game
  onClick: (game: Game, remainSeconds: number) => void
}

export const GameDetails = ({ game, onClick }: Props) => {
  const [isShowCards, setIsShowCards] = useState<boolean>()

  const statusClasses = classNames({
    [styles.bright]:
      game.gameStatus === GameStatus.START_PREDICTION || // 予想中
      (game.gameStatus === GameStatus.START_MATCH && game.gameDeck) || // デッキ編成済 & 試合中
      (game.gameStatus === GameStatus.END_PREDICTION && game.gameDeck), // デッキ編成済 & 予想終了・試合前
    [styles.dim]: game.gameStatus === GameStatus.WAIT_PREDICTION || game.gameStatus === GameStatus.START_AGGREGATION,
  })

  const title = useMemo(() => {
    return game.title
  }, [game.title])

  const matchDate = useMemo(() => {
    return new Date(game.date)
  }, [game.date])

  const displayMatchDate = useMemo(() => {
    return dayjs(matchDate).format('YYYY.M.D (ddd) H:mm')
  }, [matchDate])

  const { remainSeconds } = useRemainTime(new Date(game.predictionEndAt))

  const remainTime = useMemo<string | undefined>(() => {
    if (remainSeconds === undefined || remainSeconds === 0) {
      return
    }
    const day = Math.floor(remainSeconds / (60 * 60) / 24)
    const hours = Math.floor((remainSeconds - day * 24 * 60 * 60) / (60 * 60))
    const minuts = Math.floor((remainSeconds - day * 24 * 60 * 60 - hours * 60 * 60) / 60)
    const seconds = remainSeconds - day * 24 * 60 * 60 - hours * 60 * 60 - minuts * 60

    const displayDay = day > 0 ? `${day}日` : ''
    const displayHours = hours > 0 ? `${hours}時間` : ''
    const displayMinuts = minuts > 0 ? `${minuts}分` : ''
    const displaySeconds = seconds > 0 ? `${seconds}秒` : ''

    if (remainSeconds >= 60) return `あと${displayDay}${displayHours}${displayMinuts}`
    else return `あと${displaySeconds}` // 仕様memo: 1分切ったら秒表示
  }, [remainSeconds])

  const captainCardElement = useMemo<JSX.Element | undefined>(() => {
    if (!game.gameDeck?.captain) return
    const imagePath = game.gameDeck.captain.mainImageUrl
    const name = game.gameDeck.captain.player.name
    return <Image src={imagePath} alt={name} />
  }, [game.gameDeck])

  const q1CardElement = useMemo<JSX.Element | undefined>(() => {
    if (!game.gameDeck?.q1) return
    const imagePath = game.gameDeck.q1.mainImageUrl
    const name = game.gameDeck.q1.player.name
    return <Image src={imagePath} alt={name} />
  }, [game.gameDeck])

  const q2CardElement = useMemo<JSX.Element | undefined>(() => {
    if (!game.gameDeck?.q2) return
    const imagePath = game.gameDeck.q2.mainImageUrl
    const name = game.gameDeck.q2.player.name
    return <Image src={imagePath} alt={name} />
  }, [game.gameDeck])

  const q3CardElement = useMemo<JSX.Element | undefined>(() => {
    if (!game.gameDeck?.q3) return
    const imagePath = game.gameDeck.q3.mainImageUrl
    const name = game.gameDeck.q3.player.name
    return <Image src={imagePath} alt={name} />
  }, [game.gameDeck])

  const q4CardElement = useMemo<JSX.Element | undefined>(() => {
    if (!game.gameDeck?.q4) return
    const imagePath = game.gameDeck.q4.mainImageUrl
    const name = game.gameDeck.q4.player.name
    return <Image src={imagePath} alt={name} />
  }, [game.gameDeck])

  const cardsElements = useMemo(() => {
    return (
      <div className={styles.cards}>
        <div className={styles.captainCard}>{captainCardElement}</div>
        <div className={styles.quarterCards}>
          <div className={styles.item}>{q1CardElement}</div>
          <div className={styles.item}>{q2CardElement}</div>
          <div className={styles.item}>{q3CardElement}</div>
          <div className={styles.item}>{q4CardElement}</div>
        </div>
      </div>
    )
  }, [captainCardElement, q1CardElement, q2CardElement, q3CardElement, q4CardElement])

  const thumbElement = useMemo(() => {
    let messageText
    switch (game.gameStatus) {
      case GameStatus.WAIT_PREDICTION:
        messageText = '参加受付までしばらくお待ちください'
        break
      case GameStatus.END_PREDICTION:
      case GameStatus.START_MATCH:
      case GameStatus.END_MATCH:
      case GameStatus.START_AGGREGATION:
        messageText = '参加受付を終了しました'
        break
      case GameStatus.CANCEL_MATCH:
        messageText = 'この試合は中止となりました'
    }

    return (
      <div className={styles.thumb}>
        <div className={styles.message}>{messageText}</div>
      </div>
    )
  }, [game.gameStatus])

  const gameVisual = useMemo(() => {
    if (isShowCards) return cardsElements
    else return thumbElement
  }, [cardsElements, isShowCards, thumbElement])

  const statusText = useMemo(() => {
    switch (game.gameStatus) {
      case GameStatus.WAIT_PREDICTION:
        return '準備中'
      case GameStatus.START_PREDICTION:
        return '締切'
      case GameStatus.END_PREDICTION:
        if (game.gameDeck) return 'まもなく試合開始'
        else return '受付終了'
      case GameStatus.END_MATCH:
        return '試合終了'
      case GameStatus.CANCEL_MATCH:
        return '中止'
      case GameStatus.START_MATCH:
        if (game.gameDeck) return '試合中'
        else return '受付終了'
      case GameStatus.START_AGGREGATION:
        if (game.gameDeck) return '集計中'
        else return '受付終了'
    }
  }, [game.gameDeck, game.gameStatus])

  const handleClickDetail = () => {
    onClick(game, remainSeconds)
  }

  useEffect(() => {
    // 予想受付中 || デッキ編成済で試合中止でなければカードを見せる
    const hasDeck = game.gameDeck !== undefined
    setIsShowCards(game.gameStatus === GameStatus.START_PREDICTION || (game.gameStatus !== GameStatus.CANCEL_MATCH && hasDeck))
  }, [game.gameDeck, game.gameStatus, setIsShowCards])

  return (
    <div className={styles.container} onClick={handleClickDetail}>
      {gameVisual}
      <div className={styles.title}>{title}</div>
      <div className={styles.date}>{displayMatchDate}</div>
      <div className={styles.remainTime}>
        <span className={statusClasses}>{statusText}</span>
        {remainTime}
      </div>
    </div>
  )
}
