import styles from './index.module.scss'
import Link from 'next/link'
import { SaleCard } from '@/models/sale'
import { Image } from '@/components/commons/image'

type Props = {
  saleCard: SaleCard
}

export const SaleCardItem = ({ saleCard }: Props) => {
  return (
    <Link href={`/store/sale/${saleCard.id}`}>
      <a className={styles.container}>
        <div className={styles.mainImage}>
          <Image src={saleCard.card.mainImage} alt={saleCard.card.title} width={120} height={187} />
        </div>
        <div className={styles.playerName}>{saleCard.card.player.englishName}</div>
        <div className={styles.price}>¥{saleCard.price.toLocaleString()}</div>

        <div className={styles.status}>残り {saleCard.saleCount - saleCard.entryCount} 枚</div>
      </a>
    </Link>
  )
}
