/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Banner } from '@/models/banner'
import { grpc } from '@improbable-eng/grpc-web'
import { Notifications } from '@dena/hoop-proto-client/dist/notifications_pb_service'
import { ListPublishedBannerRequest, ListPublishedBannerResponse } from '@dena/hoop-proto-client/dist/notifications_pb'
import { HoopErrorUnknown } from '@/lib/error'
import { mapBanner } from './parser'

export function listPublishedBanner(token: string): Promise<Banner[]> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new ListPublishedBannerRequest()

    grpc.unary(Notifications.ListPublishedBanner, {
      request,
      host,
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as ListPublishedBannerResponse.AsObject
          console.info('ListPublishedBanner data = ', data)

          const banners = data.bannersList.map((item) => mapBanner(item)!)
          resolve(banners)
        } else {
          reject(new HoopErrorUnknown(`failed ListPublishedBannerRequest status = ${status}`))
        }
      },
    })
  })
}
