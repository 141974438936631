import styles from './index.module.scss'
import SwiperCore, { Mousewheel } from 'swiper'
import { useCallback, useMemo } from 'react'
import { GameDetails } from '@/components/home/latest-games/details'
import { Game, GameStatus } from '@/models/game'
import { useErrorModal } from '@/hooks/use-error-modal'
import { useSuccessModal } from '@/hooks/use-success-modal'
import { useRouter } from 'next/router'
import { EndOfSeason } from '../end-of-season'

SwiperCore.use([Mousewheel])

type Props = {
  games: Game[]
}

export const LatestGames = ({ games }: Props) => {
  const { open: openErrorModal, close: closeErrorModal } = useErrorModal()
  const { open: openSuccessModal, close: closeSuccessModal } = useSuccessModal()
  const router = useRouter()

  const handleClickGame = useCallback(
    (game: Game, remainSeconds: number) => {
      switch (game.gameStatus) {
        case GameStatus.CANCEL_MATCH:
          openErrorModal({
            title: 'この試合は中止となりました',
            description: '試合の振替スケジュールは新着情報、\nまたは公式HPをご確認ください',
            callback: () => {
              closeErrorModal()
            },
          })
          break
        case GameStatus.START_PREDICTION:
          if (remainSeconds > 0) {
            router.push(`/games/${game.id}/deck`)
          } else {
            // MEMO: 表示中に参加受付締め切り時間を過ぎた場合
            if (game.gameDeck) {
              openSuccessModal({
                title: 'デッキ編成を締め切りました',
                description: '試合開始まで今しばらくお待ちください。',
                callback: () => {
                  closeSuccessModal()
                },
              })
            } else {
              openErrorModal({
                title: '参加受付を終了しました',
                description: '試合の活躍予想をするには、試合開始時間までに選手カードを登録してください。',
                callback: () => {
                  closeErrorModal()
                },
              })
            }
          }
          break
        case GameStatus.END_MATCH:
          router.push(`/games/${game.id}/result`)
          break
        case GameStatus.START_MATCH:
          if (game.gameDeck) {
            router.push(`/games/${game.id}/matching`)
          } else {
            openErrorModal({
              title: '参加受付を終了しました',
              description: '試合の活躍予想をするには、試合開始時間までに選手カードを登録してください。',
              callback: () => {
                closeErrorModal()
              },
            })
          }
          break
        case GameStatus.START_AGGREGATION:
          if (game.gameDeck) {
            router.push(`/games/${game.id}/matching`)
          } else {
            openErrorModal({
              title: '参加受付を終了しました',
              description: '試合の活躍予想をするには、試合開始時間までに選手カードを登録してください。',
              callback: () => {
                closeErrorModal()
              },
            })
          }
          break
        case GameStatus.END_PREDICTION:
          if (game.gameDeck) {
            openSuccessModal({
              title: 'デッキ編成を締め切りました',
              description: '試合開始まで今しばらくお待ちください。',
              callback: () => {
                closeSuccessModal()
              },
            })
          } else {
            openErrorModal({
              title: '参加受付を終了しました',
              description: '試合の活躍予想をするには、試合開始時間までに選手カードを登録してください。',
              callback: () => {
                closeErrorModal()
              },
            })
          }
          break
      }
    },
    [closeErrorModal, closeSuccessModal, openErrorModal, openSuccessModal, router],
  )

  const itemElements = useMemo(() => {
    // 試合中 or 予想受付中のGameを予想終了日昇順で表示
    const gameList = games
      .filter((game) => game.gameStatus === GameStatus.START_PREDICTION || game.gameStatus === GameStatus.START_MATCH)
      .sort((a, b) => {
        return a.predictionEndAt > b.predictionEndAt ? 1 : -1
      })

    const items = gameList.slice(0, 3).map((game) => {
      return (
        <div className={styles.item} key={game.id}>
          <GameDetails game={game} onClick={handleClickGame} />
        </div>
      )
    })
    return items
  }, [games, handleClickGame])

  // NOTE: 「試合中 or 予想受付中のGame」が0件の場合は、「シーズン終了のお知らせ」を表示
  const endOfSeasonElement = useMemo(() => {
    if (itemElements.length > 0) return
    return <EndOfSeason />
  }, [itemElements.length])

  return (
    <div className={styles.container}>
      <div className={styles.list}>{itemElements}</div>
      {endOfSeasonElement}
    </div>
  )
}
