import styles from './index.module.scss'
import { SaleTypeBadge } from '@/components/store/commons/sale-type-badge'
import { SaleCard, SaleType } from '@/models/sale'
import { Image } from '@/components/commons/image'
import { Countdown } from '@/components/commons/countdown'
import Link from 'next/link'
import { useMemo } from 'react'
import CardIcon from '@/assets/icons/cards.svg'
import UsersIcon from '@/assets/icons/users.svg'
import StopWatchIcon from '@/assets/icons/stopwatch.svg'

type Props = {
  saleCard: SaleCard
}

export const SaleCardItem = ({ saleCard }: Props) => {
  const entryStatusElement = useMemo(() => {
    if (saleCard.saleType === SaleType.FIRST_SERVED) {
      // 先着販売
      const remainingCount = saleCard.saleCount - saleCard.entryCount
      return (
        <div className={styles.status}>
          <div className={styles.status__inner}>
            <CardIcon width={20} height={20} />
            残り {remainingCount} 枚（ {saleCard.saleCount} 枚限定）
          </div>
        </div>
      )
    } else {
      // 抽選販売
      return (
        <div className={styles.status}>
          <div className={styles.status__inner}>
            <UsersIcon width={20} height={20} />
            購入申込数
          </div>
          <div className={styles.status__number}>
            {saleCard.entryCount}/{saleCard.saleCount}
          </div>
        </div>
      )
    }
  }, [saleCard])

  const countdownStatusElement = useMemo(() => {
    if (saleCard.saleType === SaleType.FIRST_SERVED) {
      // 先着販売
      if (saleCard.saleCount - saleCard.entryCount <= 0) {
        // 売り切れ
        return (
          <div className={styles.status}>
            <div className={styles.status__inner}>
              <StopWatchIcon width={20} height={20} />
              販売終了
            </div>
            <span className={styles.soldout}>Sold Out</span>
          </div>
        )
      } else {
        // 販売中
        return (
          <div className={styles.status}>
            <div className={styles.status__inner}>
              <StopWatchIcon width={20} height={20} />
              販売終了まで
            </div>
            <Countdown date={saleCard.saleEndedAt} />
          </div>
        )
      }
    } else {
      // 抽選販売
      return (
        <div className={styles.status}>
          <div className={styles.status__inner}>
            <StopWatchIcon width={20} height={20} />
            申込締切まで
          </div>
          <Countdown date={saleCard.saleEndedAt} />
        </div>
      )
    }
  }, [saleCard])

  return (
    <Link href={`/store/sale/${saleCard.id}`}>
      <a className={styles.container}>
        <div className={styles.mainImage}>
          <Image src={saleCard.card.mainImage} alt={saleCard.card.title} width={200} height={311} />
        </div>
        <div className={styles.playerName}>{saleCard.card.player.englishName}</div>
        <div className={styles.title}>{saleCard.card.title}</div>
        <div className={styles.meta}>
          <SaleTypeBadge saleType={saleCard.saleType} />
          <div className={styles.price}>¥{saleCard.price.toLocaleString()}</div>
        </div>

        {entryStatusElement}

        {countdownStatusElement}
      </a>
    </Link>
  )
}
