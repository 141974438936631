import { grpc } from '@improbable-eng/grpc-web'
import { Hoop } from '@dena/hoop-proto-client/dist/hoop_pb_service'
import { ListGameRanksRequest, ListGameRanksResponse } from '@dena/hoop-proto-client/dist/hoop_pb'
import { HoopErrorUnknown } from '@/lib/error'
import { GameRank } from '@/models/game-rank'
import { mapGameRank } from './parser'

type ResultData = {
  gameRanks: GameRank[]
  totalPage: number
  currentPage: number
}

export async function listGameRanks(token: string, gameId: string, page: number, userId?: string): Promise<ResultData> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new ListGameRanksRequest()
    request.setGameId(gameId)
    request.setPage(page)

    if (userId) {
      request.setUserId(userId)
    }

    grpc.unary(Hoop.ListGameRanks, {
      request,
      host,
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as ListGameRanksResponse.AsObject
          console.info('ListGameRanks data = ', data)

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const isGameRank = (value: any): value is GameRank => {
            return value !== undefined
          }

          resolve({
            gameRanks: data.gameRanksList.map((gameRank) => mapGameRank(gameRank)).filter(isGameRank),
            totalPage: data.totalPage,
            currentPage: data.currentPage,
          })
        } else {
          reject(new HoopErrorUnknown(`failed ListGameRanksRequest status = ${status}`))
        }
      },
    })
  })
}
