import styles from './index.module.scss'
import { GameRank } from '@/models/game-rank'
import { ButtonType } from '@/models/button'
import { useMemo, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { RankListItem } from './item'
import { ButtonOutline } from '@/components/commons/button-outline'
import { ButtonGold } from '@/components/commons/button-gold'
import { useAnalytics } from '@/hooks/use-analytics'
import { useSiteData } from '@/hooks/use-site-data'
import { useHome } from '@/hooks/pages/index'
import { useLoading } from '@/hooks/use-loading'

type Props = {
  gameId: string
  gameRank?: GameRank
  gameRanks: GameRank[]
}

export const Ranking = ({ gameId, gameRank, gameRanks }: Props) => {
  const { sendEvent } = useAnalytics()
  const { user } = useSiteData()
  const { stateMyRankPage, getMyRankPageNumber } = useHome()
  const router = useRouter()
  const { loadingStart } = useLoading()

  const rankElements = useMemo(() => {
    return gameRanks.slice(0, 5).map((gameRank) => {
      return (
        <li className={styles.list__item} key={gameRank.userName}>
          <RankListItem gameRank={gameRank} />
        </li>
      )
    })
  }, [gameRanks])

  const handleClickRankingAll = useCallback(() => {
    sendEvent('click', { category: 'game_result', label: 'ranking_all_button' })
    router.push(`/games/${gameId}/result/ranking`)
  }, [gameId, router, sendEvent])

  const handleClickMyRankLink = useCallback(() => {
    if (!gameRanks || !user) return
    loadingStart()
    getMyRankPageNumber(gameId, user.id)
  }, [gameId, gameRanks, user])

  const handleClickGamesLink = useCallback(() => {
    sendEvent('click', { category: 'home', label: 'games_button' })
    router.push('/games')
  }, [router, sendEvent])

  useEffect(() => {
    if (stateMyRankPage?.result) {
      sendEvent('click', { category: 'game_result', label: 'ranking_me_button' })
      router.push(`/games/${gameId}/result/ranking/${stateMyRankPage.result.page}`)
    }
  }, [gameId, router, sendEvent, stateMyRankPage])

  return (
    <div className={styles.container}>
      <div className={styles.listHead}>
        <div className={styles.listHead__rank}>順位</div>
        <div className={styles.listHead__user}>ユーザ</div>
        <div className={styles.listHead__score}>スコア</div>
      </div>
      <ul>{rankElements}</ul>
      <div className={styles.buttons}>
        <div className={styles.button}>
          <ButtonOutline isActive={true} onClick={handleClickRankingAll} type={ButtonType.BUTTON}>
            全ランキング
          </ButtonOutline>
        </div>
        <div className={styles.button}>
          <ButtonOutline isActive={!!gameRank} onClick={handleClickMyRankLink} type={ButtonType.BUTTON}>
            自分の順位
          </ButtonOutline>
        </div>
      </div>
      <div className={styles.gamesButton}>
        <ButtonGold type={ButtonType.BUTTON} isActive={true} onClick={handleClickGamesLink}>
          試合一覧
        </ButtonGold>
      </div>
    </div>
  )
}
