import styles from './index.module.scss'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { HeadLine } from '@/components/commons/headline'
import { LatestGames } from '@/components/home/latest-games'
import { Result } from '@/components/home/result'
import { PointsComponent } from '@/components/home/points'
import { StoreContents } from '@/components/home/store'
import { TutorialModal } from '@/components/home/tutorial-modal'
import { BannerSlider } from '@/components/home/banner-slider'
import { Banner } from '@/models/banner'
import { Game } from '@/models/game'
import { useAnalytics } from '@/hooks/use-analytics'
import { Image } from '@/components/commons/image'
import { SaleCard } from '@/models/sale'
import { ListPointLottery } from '@/models/point'
import Link from 'next/link'

type Props = {
  bannerList: Banner[]
  latestGames: Game[]
  resultGame?: Game
  specialCards: SaleCard[]
  regularCards: SaleCard[]
  homeAccessCount?: number
  currentPoint: number
  lotteries: ListPointLottery[]
}

export const HomeContents = ({ bannerList, latestGames, resultGame, specialCards, regularCards, homeAccessCount, currentPoint, lotteries }: Props) => {
  const router = useRouter()
  const { sendEvent } = useAnalytics()

  const [isOpenTutorial, setIsOpenTutorial] = useState<boolean>(false)

  const resultElement = useMemo<JSX.Element | undefined>(() => {
    if (!resultGame) return
    return (
      <div className={styles.result}>
        <div className={styles.headline}>
          <div className={styles.title}>
            Result <span>前回の試合結果</span>
          </div>
        </div>
        <Result game={resultGame} />
      </div>
    )
  }, [resultGame])

  useEffect(() => {
    if (!homeAccessCount) return
    if (homeAccessCount === 1) {
      setIsOpenTutorial(true)
    }
  }, [homeAccessCount])

  const handleCloseTutorial = useCallback(() => {
    setIsOpenTutorial(false)
  }, [])

  const handleClickGamesViewAll = useCallback(() => {
    sendEvent('click', { category: 'home', label: 'view_all_games' })
    router.push('/games')
  }, [router, sendEvent])

  const handleClickHowToPlay = useCallback(() => {
    sendEvent('click', { category: 'home', label: 'how_to_play' })
    router.push('/how-to-play')
  }, [router, sendEvent])

  const pointsElement = useMemo<JSX.Element>(() => {
    const isOpen = true
    if (!isOpen) {
      return (
        <div className={styles.points}>
          <Image src={require('@/assets/home/points-coming-soon.png')} alt="Points Coming Soon" width={375} height={375} />
        </div>
      )
    }
    return (
      <div className={styles.points}>
        <PointsComponent currentPoint={currentPoint} lotteries={lotteries} />
      </div>
    )
  }, [currentPoint, lotteries])

  const storeElement = useMemo<JSX.Element | undefined>(() => {
    if (specialCards.length === 0 && regularCards.length === 0) return

    return (
      <div className={styles.store}>
        <StoreContents specialCards={specialCards} regularCards={regularCards} />
      </div>
    )
  }, [regularCards, specialCards])

  const dailyBonusElement = useMemo<JSX.Element | undefined>(() => {
    const isOffSeason = false // MEMO: オフシーズン中はデイリー停止
    if (isOffSeason) return

    return (
      <div className={styles.dailyBonus}>
        <Link href="/daily-bonus">
          <a>
            <Image src={require('@/assets/home/daily-bonus-banner.png')} alt="FREE THROW CHALLENGE" width={335} height={100} />
          </a>
        </Link>
      </div>
    )
  }, [])

  return (
    <>
      <div className={styles.home}>
        <BannerSlider banners={bannerList} />

        <div className={styles.latestGames}>
          <div className={styles.headline}>
            <HeadLine titleJa={'開始前の試合'} titleEn={'NEW GAMES'} />
            <button onClick={handleClickGamesViewAll}>- VIEW ALL</button>
          </div>
          <LatestGames games={latestGames} />
        </div>

        {resultElement}

        {pointsElement}

        {storeElement}

        {dailyBonusElement}

        <div className={styles.howToPlay}>
          <button onClick={handleClickHowToPlay}>
            <Image src={require('@/assets/home/how-to-play.png')} alt="HOW TO PLAY" width={335} height={76} />
          </button>
        </div>
      </div>

      <TutorialModal isOpen={isOpenTutorial} onClose={handleCloseTutorial} />
    </>
  )
}
