/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SaleCard } from '@/models/sale'
import { grpc } from '@improbable-eng/grpc-web'
import { App } from '@dena/hoop-proto-client/dist/app_pb_service'
import { ListAvailableSalesRequest, ListAvailableSalesResponse } from '@dena/hoop-proto-client/dist/app_pb'
import { HoopErrorUnknown } from '@/lib/error'
import { mapSaleCard } from '@/lib/grpc/parser'

type Result = {
  specialSales: SaleCard[]
  regularSales: SaleCard[]
  comingSoonSales: SaleCard[]
}

export function listAvailableSaleCards(token: string): Promise<Result> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new ListAvailableSalesRequest()

    grpc.unary(App.ListAvailableSales, {
      request,
      host,
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response

        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as ListAvailableSalesResponse.AsObject
          console.info('ListAvailableSales data = ', data)
          const specialSales = data.specialSalesList.map((item) => mapSaleCard(item)!)
          const regularSales = data.regularSalesList.map((item) => mapSaleCard(item)!)
          const comingSoonSales = data.upcomingSalesList.map((item) => mapSaleCard(item)!)

          resolve({
            specialSales,
            regularSales,
            comingSoonSales,
          })
        } else {
          reject(new HoopErrorUnknown(`failed ListAvailableSales status = ${status}`))
        }
      },
    })
  })
}
