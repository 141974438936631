import styles from './index.module.scss'
import { ListPointLottery } from '@/models/point'
import { ButtonType } from '@/models/button'
import { ItemsSwiper } from '@/components/home/points/items-swiper'
import { Image } from '@/components/commons/image'
import { ButtonGold } from '@/components/commons/button-gold'
import PointIcon from '@/assets/icons/points/point.svg'
import ArrowRight from '@/assets/icons/angle-right.svg'
import Link from 'next/link'

type Props = {
  currentPoint: number
  lotteries: ListPointLottery[]
}

export const PointsComponent = ({ currentPoint, lotteries }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <Image src={require('@/assets/home/points-bg.png')} webp={require('@/assets/home/points-bg.png?webp')} alt="point prize" width={375} height={749} />
      </div>
      <div className={styles.inner}>
        <div className={styles.head}>
          <Image src={require('@/assets/home/points-head.png')} webp={require('@/assets/home/points-head.png?webp')} alt="ポイントくじで豪華賞品をゲットしよう！" width={294} height={97} />
        </div>
        <div className={styles.items}>
          <ItemsSwiper items={lotteries} />
        </div>

        <div className={styles.buttons}>
          <Link href={'/points/history'}>
            <a>
              <div className={styles.userPoint}>
                <div className="left">保有ポイント</div>
                <div className="right">
                  <PointIcon width={24} height={24} />
                  <div className="value">{currentPoint.toLocaleString()}</div>
                  <div className="unit">pt</div>
                  <ArrowRight width={24} height={24} />
                </div>
              </div>
            </a>
          </Link>

          <ButtonGold type={ButtonType.LINK} href={'/points'} isActive={true}>
            ポイントくじ
          </ButtonGold>
        </div>
      </div>
    </div>
  )
}
