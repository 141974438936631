import styles from './index.module.scss'
import { Pagination, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MousewheelOptions } from 'swiper/types'
import { PaginationOptions } from 'swiper/types'
import { Banner } from '@/models/banner'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useAnalytics } from '@/hooks/use-analytics'
import { Image } from '@/components/commons/image'

type Props = {
  banners: Banner[]
}

export const BannerSlider = ({ banners }: Props) => {
  const router = useRouter()
  const paginationRef = useRef(null)
  const [swiperElement, setSwiperElement] = useState<JSX.Element | undefined>()
  const { sendEvent } = useAnalytics()

  const handleClick = useCallback(
    (banner: Banner) => {
      if (!banner.url.length) return
      sendEvent('click', { category: 'home', label: `banner_${banner.url}` })
      router.push(banner.url)
    },
    [router, sendEvent],
  )

  useEffect(() => {
    if (!paginationRef.current) {
      return
    }

    const mousewheelOptions: MousewheelOptions = {
      forceToAxis: true,
      sensitivity: 0.5,
      thresholdDelta: 25,
    }

    const paginationOptions: PaginationOptions = {
      el: paginationRef.current,
      clickable: true,
      bulletClass: styles.bullet,
      bulletActiveClass: styles.bulletActive,
    }

    const bannerElements = banners.map((banner, index) => {
      return (
        <SwiperSlide key={index}>
          <div className={styles.banner} onClick={() => handleClick(banner)}>
            <Image src={banner.imageUrl} width={375} height={375} alt={banner.title} />
          </div>
        </SwiperSlide>
      )
    })

    const element = (
      <Swiper modules={[Pagination, Mousewheel]} className={styles.swiperWrap} slidesPerView={'auto'} pagination={paginationOptions} mousewheel={mousewheelOptions}>
        {bannerElements}
      </Swiper>
    )

    setSwiperElement(element)
  }, [handleClick, banners])

  return (
    <div className={styles.container}>
      {swiperElement}
      <div className={styles.navi}>
        <div className={styles.pagination} ref={paginationRef} />
      </div>
    </div>
  )
}
