import type { NextPage } from 'next'
import { GetServerSidePropsResult } from 'next'
import router from 'next/router'
import { HomeContents } from '@/components/home'
import { useEffect, useMemo } from 'react'
import { useLoading } from '@/hooks/use-loading'
import { useSiteData } from '@/hooks/use-site-data'
import { useHome } from '@/hooks/pages/index'
import { useErrorModal } from '@/hooks/use-error-modal'
import { LayoutDefault } from '@/components/layouts/default'
import getGuardPath from '@/guards/pages/index'
import { Game } from '@/models/game'
import { HoopMetadata } from '@/models/metadata'

type Props = {
  meta?: HoopMetadata
}

export async function getServerSideProps(): Promise<GetServerSidePropsResult<Props>> {
  return {
    props: {
      meta: {
        title: 'PICKFIVE（ピックファイブ）- あなたは、どの5人で戦う？',
        description: 'PICKFIVE（ピックファイブ）は、試合で活躍する川崎ブレイブサンダースの選手を予想して楽しむことのできるサービスです。',
      },
    },
  }
}

const Home: NextPage = () => {
  const { isLogin, isCreateUser, isSetBirthday, answeredEnquete, hasWallet, isMaintenance } = useSiteData()
  const { state, getPageData, setHomeAccessCount } = useHome()
  const { loadingFinish } = useLoading()
  const { open: openErrorModal } = useErrorModal()

  const homeComponent = useMemo(() => {
    if (!state?.result) {
      return
    }

    return <HomeContents bannerList={state.result.bannerList} latestGames={state.result.games} resultGame={state.result.resultGame} specialCards={state.result.specialSales} regularCards={state.result.regularSales} homeAccessCount={state.result.homeAccessCount} currentPoint={state.result.currentPoint} lotteries={state.result.lotteries} />
  }, [state?.result])

  const matchingGame = useMemo<Game | undefined>(() => {
    if (!state || state.isLoading || !state.result) return
    return state.result.matchingGame
  }, [state])

  useEffect(() => {
    const guardPath = getGuardPath({ isLogin, isCreateUser, isSetBirthday, answeredEnquete, hasWallet, isMaintenance })
    if (guardPath) {
      router.push(guardPath)
      return
    }

    getPageData()
  }, [])

  useEffect(() => {
    if (!state || state.isLoading) {
      return
    }

    if (state.result) {
      loadingFinish()
    } else {
      openErrorModal({})
    }
  }, [loadingFinish, openErrorModal, state])

  useEffect(() => {
    if (!state?.result?.homeAccessCount) return
    setHomeAccessCount(state.result.homeAccessCount)
  }, [setHomeAccessCount, state?.result])

  return <LayoutDefault matchingGame={matchingGame}>{homeComponent}</LayoutDefault>
}

export default Home
