import styles from './index.module.scss'
import { HeadLine } from '@/components/commons/headline'
import { ButtonGold } from '@/components/commons/button-gold'
import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useAnalytics } from '@/hooks/use-analytics'
import { SpecialCards } from '@/components/home/store/special-cards'
import { RegularCards } from '@/components/home/store/regular-cards'
import { SaleCard } from '@/models/sale'
import { ButtonType } from '@/models/button'

type Props = {
  specialCards: SaleCard[]
  regularCards: SaleCard[]
}

export const StoreContents = ({ specialCards, regularCards }: Props) => {
  const router = useRouter()
  const { sendEvent } = useAnalytics()

  const handleClickStoreButton = useCallback(() => {
    sendEvent('click', { category: 'home', label: 'store' })
    router.push('/store')
  }, [router, sendEvent])

  const specialCardsElement = useMemo(() => {
    if (specialCards.length === 0) return
    return <SpecialCards saleCards={specialCards} />
  }, [specialCards])

  const regularCardsElement = useMemo(() => {
    if (regularCards.length === 0) return
    return <RegularCards saleCards={regularCards} />
  }, [regularCards])

  return (
    <div className={styles.container}>
      <div className={styles.headline}>
        <HeadLine titleJa={'ストア'} titleEn={'STORE'} />
      </div>

      {specialCardsElement}

      {regularCardsElement}

      <div className={styles.storeButton}>
        <ButtonGold type={ButtonType.BUTTON} isActive={true} onClick={handleClickStoreButton}>
          販売NFTカード一覧
        </ButtonGold>
      </div>
    </div>
  )
}
