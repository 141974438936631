import styles from './index.module.scss'
import { SubHeadLine } from '@/components/commons/sub-headline'
import { SaleCardItem } from '@/components/home/store/special-cards/sale-card-item'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PaginationOptions, MousewheelOptions, SwiperModule } from 'swiper/types'
import BackgroundImage from '@/assets/home/store-background.svg'
import { useEffect, useRef, useState } from 'react'
import { SaleCard } from '@/models/sale'
import { Pagination, Mousewheel } from 'swiper'

type Props = {
  saleCards: SaleCard[]
}

export const SpecialCards = ({ saleCards }: Props) => {
  const paginationRef = useRef(null)
  const [swiperElement, setSwiperElement] = useState<JSX.Element | undefined>()

  useEffect(() => {
    if (!paginationRef.current) return

    const slideElements = saleCards.slice(0, 10).map((item, i) => {
      return (
        <SwiperSlide key={i}>
          <SaleCardItem saleCard={item} />
        </SwiperSlide>
      )
    })

    const paginationOptions: PaginationOptions = {
      el: paginationRef.current,
      clickable: true,
      bulletClass: styles.bullet,
      bulletActiveClass: styles.bulletActive,
    }

    const mousewheelOptions: MousewheelOptions = {
      forceToAxis: true,
      sensitivity: 0.5,
      thresholdDelta: 25,
    }

    // NOTE: 販売NFTカードが1枚のときは pagination は無し、swiperをセンタリングにする
    const modules: SwiperModule[] = saleCards.length === 1 ? [Mousewheel] : [Pagination, Mousewheel]
    const centeredSlides = saleCards.length === 1

    const swiperElement = (
      <Swiper className={styles.list} modules={modules} slidesPerView={1.61364} spaceBetween={20} centeredSlides={centeredSlides} pagination={paginationOptions} mousewheel={mousewheelOptions}>
        {slideElements}
      </Swiper>
    )
    setSwiperElement(swiperElement)
  }, [saleCards])

  return (
    <section className={styles.container}>
      <div className={styles.contents}>
        <div className={styles.subHeadline}>
          <SubHeadLine titleJa={'期間限定販売'} titleEn={'Special Cards'} />
        </div>
        {swiperElement}
        <div className={styles.pagination} ref={paginationRef} />
      </div>
      <div className={styles.background}>
        <BackgroundImage width={375} height={640} />
      </div>
    </section>
  )
}
