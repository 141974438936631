import styles from './index.module.scss'
import { ResultDetails } from './details'
import { Game } from '@/models/game'
import { Ranking } from './ranking'
import { useMemo } from 'react'

type Props = {
  game: Game
}

export const Result = ({ game }: Props) => {
  const RankingComponent = useMemo<JSX.Element | undefined>(() => {
    if (!game.gameRanking) return
    return <Ranking gameId={game.id} gameRank={game.gameRank} gameRanks={game.gameRanking.gameRanks} />
  }, [game])

  return (
    <div className={styles.container}>
      <ResultDetails game={game} />
      {RankingComponent}
    </div>
  )
}
