import styles from './index.module.scss'
import dayjs from 'dayjs'
import { useMemo, useCallback } from 'react'
import { Game } from '@/models/game'
import { useRouter } from 'next/router'
import { Image } from '@/components/commons/image'

type Props = {
  game: Game
}

export const ResultDetails = ({ game }: Props) => {
  const router = useRouter()
  const matchDateString = useMemo<string>(() => {
    return dayjs(game.date).format('YYYY.M.D (ddd) HH:mm')
  }, [game.date])

  const handleClickCards = useCallback(() => {
    router.push(`/games/${game.id}/result`)
  }, [game.id, router])

  const cardsElements = useMemo<JSX.Element | undefined>(() => {
    if (!game.gameDeck) return
    return (
      <div className={styles.cards}>
        <div className={styles.captainCard}>
          <Image src={game.gameDeck.captain.mainImageUrl} alt={game.gameDeck.captain.player.name} width={133} height={207} />
        </div>
        <div className={styles.quarterCards}>
          <div className={styles.item}>
            <Image src={game.gameDeck.q1.mainImageUrl} alt={game.gameDeck.q1.player.name} width={64} height={100} />
          </div>
          <div className={styles.item}>
            <Image src={game.gameDeck.q2.mainImageUrl} alt={game.gameDeck.q2.player.name} width={64} height={100} />
          </div>
          <div className={styles.item}>
            <Image src={game.gameDeck.q3.mainImageUrl} alt={game.gameDeck.q3.player.name} width={64} height={100} />
          </div>
          <div className={styles.item}>
            <Image src={game.gameDeck.q4.mainImageUrl} alt={game.gameDeck.q4.player.name} width={64} height={100} />
          </div>
        </div>
      </div>
    )
  }, [game.gameDeck])

  const rankingElement = useMemo<JSX.Element>(() => {
    if (game.gameRank?.rank) {
      return <span className={styles.value}>{game.gameRank?.rank.toLocaleString()}</span>
    } else {
      return <span className={styles.value}> --- </span>
    }
  }, [game.gameRank?.rank])

  const pointElement = useMemo<JSX.Element>(() => {
    if (game.gameRank?.pickfivePoint) {
      return <span className={styles.value}>{game.gameRank?.pickfivePoint.toLocaleString()}</span>
    } else {
      return <span className={styles.value}> --- </span>
    }
  }, [game.gameRank?.pickfivePoint])

  const score = useMemo<number | string>(() => {
    if (!game.gameRank) return '---'
    return game.gameRank.point
  }, [game.gameRank])

  return (
    <div className={styles.root} onClick={handleClickCards}>
      <div className={styles.bg}>
        <Image src={require('@/assets/games/result/bg.png')} webp={require('@/assets/games/result/bg.png?webp')} width={335} alt="" />
      </div>
      <div className={styles.contents}>
        {cardsElements}
        <div className={styles.game}>
          <p className={styles.team}>{game.title}</p>
          <p className={styles.date}>{matchDateString}</p>
        </div>
        <div className={styles.rank_point}>
          <div className={styles.rank}>
            <p className={styles.title}>ランキング</p>
            <p>
              {rankingElement}
              <span className={styles.unit}>位</span>
            </p>
            <div className={styles.score}>( スコア: {score} )</div>
          </div>
          <div className={styles.point}>
            <p className={styles.title}>獲得ポイント</p>
            <p>
              {pointElement}
              <span className={styles.unit}>pt</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
