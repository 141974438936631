import { doc, FirestoreDataConverter, QueryDocumentSnapshot, DocumentData, setDoc, getDoc } from 'firebase/firestore'
import { getFirestoreClient } from '@/lib/firebase/'

type HomeAccessCount = {
  homeAccessCount: number
}

const converter: FirestoreDataConverter<HomeAccessCount> = {
  toFirestore: (model: HomeAccessCount): DocumentData => {
    return model
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot): HomeAccessCount => {
    const data = snapshot.data()
    let count = 0
    if (data.homeAccessCount) {
      count = data.homeAccessCount
    }
    return {
      homeAccessCount: count + 1,
    }
  },
}

export async function setHomeAccessCount(uid: string, count: number): Promise<void> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private')
  await setDoc(query, { homeAccessCount: count }, { merge: true })
}

export async function getHomeAccessCount(uid: string): Promise<number | undefined> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private').withConverter(converter)
  const snapshot = await getDoc(query)
  const data = snapshot.data()

  return data?.homeAccessCount
}

export async function resetHomeAccessCount(uid: string): Promise<void> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private')
  await setDoc(query, { homeAccessCount: 0 }, { merge: true })
}
