import styles from './index.module.scss'
import { GameRank } from '@/models/game-rank'
import { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useSiteData } from '@/hooks/use-site-data'
import { useAnalytics } from '@/hooks/use-analytics'
import { Image } from '@/components/commons/image'
import { useRouter } from 'next/router'
import { mapProfileIconColor } from '@/lib/grpc/parser/profile'

type Props = {
  gameRank: GameRank
}

export const RankListItem = ({ gameRank }: Props) => {
  const { user } = useSiteData()
  const { sendEvent } = useAnalytics()
  const router = useRouter()
  const [iconImage, setIconImage] = useState(`${process.env.NEXT_PUBLIC_ASSET_HOST}/public/images/users/${gameRank.userId}/icon.@2x.jpeg`)

  const isMe = useMemo(() => {
    if (!user) return false
    return user.userName === gameRank.userName
  }, [gameRank.userName, user])

  const isDeletedUser = useMemo(() => {
    return gameRank.nickname.length === 0
  }, [gameRank.nickname])

  const containerClasses = classNames(styles.container, { [styles.isMe]: isMe })
  const nickNameClasses = classNames(styles.nickname, { [styles.isDeletedUser]: isDeletedUser })

  const handleClickRankingItem = () => {
    if (isDeletedUser) return
    sendEvent('click', { category: 'game_result', label: 'ranking_other_user' })
    router.push(`/user/${gameRank.userName}`)
  }

  const handleError = useCallback(() => {
    const iconColor = mapProfileIconColor(gameRank.userId)
    setIconImage(`${require(`@/assets/user/profile-icon-${iconColor}.png`)}`)
  }, [setIconImage, gameRank])

  return (
    <div className={containerClasses} onClick={handleClickRankingItem}>
      <div className={styles.rank}>
        {gameRank.rank}
        <span className={styles.rank__suffix}>位</span>
      </div>
      <div className={styles.icon}>
        <Image src={iconImage} width={24} height={24} alt={gameRank.nickname} onError={handleError} />
      </div>
      <div className={nickNameClasses}>{isDeletedUser ? '退会済みユーザ' : gameRank.nickname}</div>
      <div className={styles.score}>{gameRank.point.toLocaleString()}</div>
    </div>
  )
}
