/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { grpc } from '@improbable-eng/grpc-web'
import { HoopErrorUnknown } from '@/lib/error'
import { Hoop } from '@dena/hoop-proto-client/dist/hoop_pb_service'
import { ListAvailableLotteriesRequest, ListAvailableLotteriesResponse } from '@dena/hoop-proto-client/dist/hoop_pb'
import { mapListAvailableLotteries } from '@/lib/grpc/parser/point'
import { ListPointLottery } from '@/models/point'

type Result = {
  lotteries: ListPointLottery[]
  currentPoint: number
}

export function listAvailableLotteries(token: string): Promise<Result> {
  return new Promise((resolve, reject) => {
    const request = new ListAvailableLotteriesRequest()
    grpc.unary(Hoop.ListAvailableLotteries, {
      request,
      host: process.env.NEXT_PUBLIC_API_HOST || '',
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response

        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as ListAvailableLotteriesResponse.AsObject
          console.info('ListAvailableLotteries data = ', data)

          const lotteries = data.lotteriesList.map((item) => mapListAvailableLotteries(item)!)
          const currentPoint = data.pointsHeld

          resolve({ lotteries, currentPoint })
        } else {
          reject(new HoopErrorUnknown(`failed ListAvailableLotteriesResponse status = ${status}`))
        }
      },
    })
  })
}
