import styles from './index.module.scss'
import { Pagination, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MousewheelOptions } from 'swiper/types'
import { PaginationOptions } from 'swiper/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Image } from '@/components/commons/image'
import classNames from 'classnames'
import { ListPointLottery } from '@/models/point'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import router from 'next/router'
dayjs.locale('ja')

type Props = {
  items: ListPointLottery[]
}

export const ItemsSwiper = ({ items }: Props) => {
  const paginationRef = useRef(null)
  const [swiperElement, setSwiperElement] = useState<JSX.Element | undefined>()

  const naviClasses = classNames(styles.navi, {
    [styles.invisible]: items.length === 1,
  })

  const isSwiperLoop = useMemo<boolean>(() => {
    return items.length > 1
  }, [items])

  useEffect(() => {
    if (!paginationRef.current) {
      return
    }

    const mousewheelOptions: MousewheelOptions = {
      forceToAxis: true,
      sensitivity: 0.5,
      thresholdDelta: 25,
    }

    const paginationOptions: PaginationOptions = {
      el: paginationRef.current,
      clickable: true,
      bulletClass: styles.bullet,
      bulletActiveClass: styles.bulletActive,
    }

    const itemsElements = items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div className={styles.item} onClick={() => router.push(`/points/${item.id}`)}>
            <div className={styles.image}>
              <Image src={item.itemImageUrl} width={200} height={200} alt={item.title} />
            </div>
            <div className={styles.details}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.point}>{item.requiredPoint.toLocaleString()}pt</div>
              <div className={styles.residualQuantity}>
                残り
                <span>{item.residualQuantity}</span>点
              </div>
              <div className={styles.closedAt}>{dayjs(item.closedAt).format('YYYY.MM.DD (ddd) H:mmまで')}</div>
            </div>
          </div>
        </SwiperSlide>
      )
    })

    const element = (
      <Swiper spaceBetween={17} loop={isSwiperLoop} modules={[Pagination, Mousewheel]} className={styles.swiperWrap} slidesPerView={'auto'} pagination={paginationOptions} mousewheel={mousewheelOptions}>
        {itemsElements}
      </Swiper>
    )
    setSwiperElement(element)
  }, [isSwiperLoop, items])

  return (
    <div className={styles.container}>
      {swiperElement}
      <div className={naviClasses}>
        <div className={styles.pagination} ref={paginationRef} />
      </div>
    </div>
  )
}
