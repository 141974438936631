import { useRemainTime } from '@/hooks/use-remain-time'
import { useMemo } from 'react'

type Props = {
  date: Date
}

export const Countdown = ({ date }: Props) => {
  const { remainSeconds } = useRemainTime(date)

  const element = useMemo(() => {
    const day = Math.floor(remainSeconds / (60 * 60) / 24)
    const hours = Math.floor((remainSeconds - day * 24 * 60 * 60) / (60 * 60))
    const minuts = Math.floor((remainSeconds - day * 24 * 60 * 60 - hours * 60 * 60) / 60)
    const seconds = remainSeconds - day * 24 * 60 * 60 - hours * 60 * 60 - minuts * 60

    const displayDay = day > 0 ? `${day}日` : ''
    const displayHours = hours > 0 ? `${hours}時間` : ''
    const displayMinuts = minuts > 0 ? `${minuts}分` : ''
    const displaySeconds = seconds > 0 ? `${seconds}秒` : ''

    // 仕様memo: 1分切ったら秒表示
    if (remainSeconds >= 60) {
      return <span>{`${displayDay}${displayHours}${displayMinuts}`}</span>
    } else {
      return <span>{`${displaySeconds}`}</span>
    }
  }, [remainSeconds])

  return <span>{element}</span>
}
