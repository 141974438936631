import styles from './index.module.scss'
import { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ButtonGold } from '@/components/commons/button-gold'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import { Image } from '@/components/commons/image'
import { ButtonType } from '@/models/button'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const TutorialModal = ({ isOpen, onClose }: Props) => {
  const rootElement = useRef(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()

  useEffect(() => {
    if (!rootElement.current) {
      return
    }

    if (isOpen) {
      scrollTarget(rootElement.current)
    } else {
      unScrollTarget(rootElement.current)
    }
  }, [isOpen, scrollTarget, unScrollTarget])

  return (
    <CSSTransition in={isOpen} timeout={200} unmountOnExit classNames="hoop-fade" nodeRef={rootElement}>
      <div className={styles.root} ref={rootElement}>
        <div className={styles.inner}>
          <div className={styles.image}>
            <Image src={require('@/assets/home/tutorial-modal-image-2.png')} alt="" width={281} height={231} />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>
              カードを使って
              <br />
              デッキを編成してみよう！
            </div>
            <div className={styles.description}>
              試合で活躍する選手を予想して
              <br />
              カードを5枚セットしよう！
            </div>
            <div className={styles.button}>
              <ButtonGold type={ButtonType.BUTTON} isActive={true} onClick={() => onClose()}>
                OK
              </ButtonGold>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
